import React, { useState, useEffect, useContext } from 'react';
import PropTypes from "prop-types";
import moment from 'moment'
import * as Sentry from '@sentry/browser';
import { Paper, TextField, Checkbox, MenuItem, FormControl, FormGroup, RadioGroup, Radio, FormControlLabel} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DateTimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'


import HelpToolTip from '../../../components/HelpToolTip';
import OtherDropDown from '../../../components/OtherDropDown';
import stateConfig from '../../../config/state.json'

import userService from '../../../services/user'
import AppContext from '../../../context/app'

CreateDefault.propTypes = {
    assessmentModel: PropTypes.object,
    assessment: PropTypes.object,
    institution: PropTypes.object,
    building: PropTypes.object,
    onChange: PropTypes.func
};

export default function CreateDefault(props) {
  const context = useContext(AppContext)

  const [users, setUsers] = useState([])
  const [delegateMessage, setDelegateMessage] = useState()

  useEffect( async () => {
    props.assessment.threatMadeDateIsValid = true
    props.assessment.additionalParticipants = []
    //if you do not have all the assigned interviews, default to those that can be assigned
    let assignableInterviewTypes = props.assessmentModel.interviewTypes.filter( it => !it.config.autoAssignTo && !it.config.notAssignable )
    if (props.assessment.assigned?.length < assignableInterviewTypes.length){
      props.assessment.assigned = assignableInterviewTypes.map( (it) => { 
        //if already assigned
        let assignToUserId = props.assessment.assigned.find( assignment => it.role === assignment.role )?.userId
        //default assign otherwise blank
        if (!assignToUserId)
          assignToUserId = it.config.defaultAssignTo === 'creator' ? context.user.userId : null
        return { role: it.role, userId: assignToUserId } 
      })
    }
    props.onChange(props.assessment)

    let thisStateConfig = stateConfig[props.institution.state]
    if (thisStateConfig && eval(thisStateConfig.delegateMessage.rule)(props.assessment))
      setDelegateMessage(thisStateConfig.delegateMessage.message)    
  },[])

  useEffect( async () => {
    try {      
        let users = await new userService().getInstitutionBuildingUsers(props.building.institutionBuildingId)
        users = users.map( (user) => {
          user.nameText = user.firstName ? `${user.firstName} ${user.lastName}` : user.email
          return user
        }) 
        users = users.filter( (user) => { return user.threatTeam || user.viewSelfHarm || user.viewAllThreats } )
        users = users.sort( (a, b) => { return (a.lastName + a.firstName) - (b.lastName + b.firstName)  } )
        setUsers(users)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[props.building])



  const handleChangeInquirer = (event, role, index) => {
    let userId = event.target.value
    let inquirers = props.assessment.assigned
    inquirers[index].userId = userId
    inquirers[index].role = role
    props.assessment.assigned = inquirers
    props.onChange(props.assessment)
  }

  const handleAddAnother = (role, index) => {
    let inquirers = props.assessment.assigned
    inquirers.splice(index+1, 0, { role });
    props.assessment.assigned = inquirers
    props.onChange(props.assessment)
  }

  const handleRemoveAnother = (role, index) => {
    let inquirers = props.assessment.assigned
    inquirers.splice(index, 1);
    props.assessment.assigned = inquirers
    props.onChange(props.assessment)
  }

  return (
        <div>
            <Paper className="paper">
                <h2>Incident or Behavior of Concern</h2>
                <div className="flex-row ">
                  <div className="flex-col assessedFirstName-container">
                    <TextField label={`First Name of ${ props.assessmentModel?.config.assessedLabel || 'Subject of Concern'}`} type="text" maxLength="25" value={props.assessment.assessedFirstName || ''} required onChange={(e) => { props.assessment.assessedFirstName = e.target.value; props.onChange(props.assessment)}}/>
                  </div>
                  <div className="flex-col assessedLastName-container">
                    <TextField label={`Last Name of ${ props.assessmentModel?.config.assessedLabel || 'Subject of Concern'}`} type="text" maxLength="25" value={props.assessment.assessedLastName || ''} required onChange={(e) => { props.assessment.assessedLastName = e.target.value; props.onChange(props.assessment)}}/>
                  </div>
                </div>

                <div className="flex-row ">
                  <div className="flex-col gender">
                    <TextField select label="Identification" required value={props.assessment.gender || ''} onChange={(e) => { props.assessment.gender = e.target.value; props.onChange(props.assessment)}}>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Neutral">Neutral</MenuItem>
                    </TextField>
                  </div>

                  <div className="flex-col race">
                    <TextField select label="Race" required value={props.assessment.race || ''} onChange={(e) => { props.assessment.race = e.target.value; props.onChange(props.assessment)}}>
                      <MenuItem value="African American">African American</MenuItem>
                      <MenuItem value="American Indian">American Indian</MenuItem>
                      <MenuItem value="Asian">Asian</MenuItem>
                      <MenuItem value="Latin American">Latin American</MenuItem>
                      <MenuItem value="White">White</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                  </div>


                  <div className="flex-col dateOfBirth">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker maxDate={moment().subtract(5, 'years')} minDate={moment().subtract(50,'years')} disableFuture={true} openTo='year' fullWidth renderInput={(props) => <TextField required {...props} />} label="Date Of Birth" value={props.assessment.dateOfBirth} required onError={(e) => {props.assessment.dateOfBirthIsValid = e === null; props.onChange(props.assessment)}} onChange={(e) => { if (!e) { return }; props.assessment.dateOfBirthIsValid = e.isValid();  props.assessment.dateOfBirth = e; props.onChange(props.assessment)}}/>
                    </LocalizationProvider>
                  </div>

                  <div className="flex-col">
                    <TextField label="Subject ID" type="text" maxLength="30" value={props.assessment.subjectId || ''} onChange={(e) => { props.assessment.subjectId = e.target.value; props.onChange(props.assessment)}}/>
                  </div>
                </div>

                {props.assessmentModel &&  props.assessmentModel.askExtraInfo ?
                  <div className="flex-row threatPersonType-row">
                    <div className="flex-col threatPersonType">
                      <OtherDropDown label="Affiliation of person making threat" value={props.assessment.threatPersonType} required onChange={(e) => { props.assessment.threatPersonType = e.target.value; props.onChange(props.assessment)}} >
                        <MenuItem value="Student">Student</MenuItem>
                        <MenuItem value="Faculty/Staff">Faculty/Staff</MenuItem>
                        <MenuItem value="Parent/Family">Parent</MenuItem>
                      </OtherDropDown>
                    </div>

                    <div className="flex-col">
                        <FormControl component="fieldset">
                          <RadioGroup row>
                            <FormControlLabel value="1" control={<Radio required />} label="Current" checked={props.assessment.isCurrentThreatPersonType == 1} onChange={(e) => { props.assessment.isCurrentThreatPersonType = e.target.value; props.onChange(props.assessment)}}/>
                            <FormControlLabel value="0" control={<Radio required />} label="Former" checked={props.assessment.isCurrentThreatPersonType == 0} onChange={(e) => { props.assessment.isCurrentThreatPersonType = e.target.value; props.onChange(props.assessment)}} />
                          </RadioGroup>
                        </FormControl>
                    </div>
                  </div>
                : null }

                {props.assessmentModel && props.assessmentModel.threatAssessmentModelId !== 4 && (props.assessmentModel.threatAssessmentModelId !== 1 || props.assessment.threatPersonType === 'Student') ?
                  <div className="flex-row">
                    <div className="flex-col">
                      <TextField label="Grade" type="number" InputProps={{inputProps: { min: 0 }}} required value={props.assessment.grade || ''} onChange={(e) => { props.assessment.grade = e.target.value; props.onChange(props.assessment)}}/>
                    </div>

                    { props.assessmentModel && props.assessmentModel.askExtraInfo ?
                      <div className="flex-col">
                        <TextField label="School program" type="text" maxLength="20" value={props.assessment.schoolProgram || ''} onChange={(e) => { props.assessment.schoolProgram = e.target.value; props.onChange(props.assessment)}}/>
                      </div>
                    : null }
                  </div>
                : null }


                  {props.assessmentModel &&  props.assessmentModel.askExtraInfo ?
                  <div>
                    <div className="flex-row">
                      <div className="flex-col">
                        <TextField label="Home Address" type="text" maxLength="45" value={props.assessment.assessedHomeAddress || ''} required onChange={(e) => { props.assessment.assessedHomeAddress = e.target.value; props.onChange(props.assessment)}}/>
                      </div>

                      <div className="flex-col">
                        <TextField label="Phone" type="text" maxLength="45" value={props.assessment.assessedPhone || ''} required onChange={(e) => { props.assessment.assessedPhone = e.target.value; props.onChange(props.assessment)}}/>
                      </div>
                    </div>

                    <div className="flex-row">
                      <div className="flex-col">
                        <TextField label="Emergency Contact" type="text" maxLength="45" value={props.assessment.emergencyContactName || ''} required onChange={(e) => { props.assessment.emergencyContactName = e.target.value; props.onChange(props.assessment)}}/>
                      </div>

                      <div className="flex-col">
                        <TextField label="Emergency Contact Relationship" type="text" maxLength="45" value={props.assessment.emergencyContactRelationship || ''} required onChange={(e) => { props.assessment.emergencyContactRelationship = e.target.value; props.onChange(props.assessment)}}/>
                      </div>
                    </div>
                  </div>
                : null }
              </Paper>
              
              <Paper className="paper" >
                <h2>Reported By</h2>
                <div className="flex-row">
                    <div className="flex-col reason">
                      <OtherDropDown fullWidth value={props.assessment.reason}  label="Affiliation of person reporting threat" required onChange={(e) => { props.assessment.reason = e.target.value; props.onChange(props.assessment)}} >
                          <MenuItem value="Anonymous Communication">Anonymous Communication</MenuItem>
                          <MenuItem value="Student">Student</MenuItem>
                          <MenuItem value="Faculty/Staff">Faculty/Staff</MenuItem>
                          <MenuItem value="Parent/Family">Parent/Family</MenuItem>
                      </OtherDropDown>
                    </div>
                </div>

                {props.assessmentModel &&  props.assessmentModel.askExtraInfo ?
                <div>
                  <div className="flex-row">
                    <div className="flex-col">
                      <TextField label="Name of Person Reporting Threat" type="text" maxLength="100" value={props.assessment.reportedByName || ''} required onChange={(e) => { props.assessment.reportedByName = e.target.value; props.onChange(props.assessment)}}/>
                    </div>
                    <div className="flex-col">
                      <TextField label="Name of Person Receiving the Report" type="text" maxLength="100" value={props.assessment.reportReceivedBy || ''} required onChange={(e) => { props.assessment.reportReceivedBy = e.target.value; props.onChange(props.assessment)}}/>
                    </div>
                  </div>

                  <div className="flex-row">
                    <div className="flex-col">
                      <div className="flex-row">
                        <div className="flex-col threatLocation">
                          <OtherDropDown label="Location incident occurred" value={props.assessment.threatLocation} required name="threatLocation" onChange={(e) => { props.assessment.threatLocation = e.target.value; props.onChange(props.assessment)}} >
                              <MenuItem value="School Building or Grounds">School Building or Grounds </MenuItem>
                              <MenuItem value="School Bus/Other Travel">School Bus/Other Travel </MenuItem>
                              <MenuItem value="School-Sponsored Activity">School-Sponsored Activity</MenuItem>
                              <MenuItem value="Digital communication such as text or post">Digital communication such as text or post</MenuItem>
                              <MenuItem value="Unknown">Unknown</MenuItem>
                          </OtherDropDown>
                        </div>
                        {!props.assessment.threatMadeDateUnknown ? 
                          <div className="flex-col">                            
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DateTimePicker renderInput={(props) => <TextField {...props} />} label="Incident Date" value={props.assessment.threatMadeDate} onError={(e) => {props.assessment.threatMadeDateIsValid = e === null; props.onChange(props.assessment)}} onChange={(e) => {if (!e) { return }; props.assessment.threatMadeDateIsValid = e.isValid();  props.assessment.threatMadeDate = e; props.onChange(props.assessment)}}/>
                            </LocalizationProvider>
                          </div>
                        : null }
                        <div className="flex-col threatMadeDateUnknown">
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={props.assessment.threatMadeDateUnknown} onChange={(e) => { props.assessment.threatMadeDateUnknown = e.target.checked; props.onChange(props.assessment)}} />} label="Unknown Date/Time" />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    
                    
                  </div>
                </div>
                : null }
              </Paper>

              <Paper className="paper" >
                <h2>Summary</h2>
                <div>
                  <TextField label="Summary of the Incident or Threat" multiline maxRows={5} type="text"  inputProps={{ maxLength: 8000}}  maxLength="8000" value={props.assessment.comments || ''} required onChange={(e) => { props.assessment.comments = e.target.value; props.onChange(props.assessment)}}/>
                </div>
              </Paper>

            { props.assessmentModel &&  props.assessmentModel.showAssessmentFindings ?
              <section>
                <Paper className="paper" >
                  <h2 className="hasSubtitle">Assessment Findings</h2>
                  <div className="subText">
                    All sources are not needed in most cases.
                  </div>
                  <div>
                    { props.assessmentModel.config.findings.map( (assessmentFinding, index) => {
                      const edittedFinding = props.assessment.findings?.find( f => f.informationSource === assessmentFinding.informationSource)
                      return (
                        <div key={index} className="flex-row">
                          <div className="flex-col assessmentFinding-text">
                            {assessmentFinding.informationSource}
                            { assessmentFinding.subText ? <div className="subText">{assessmentFinding.subText}</div> : null}
                          </div>

                          <div className="flex-col assessmentFinding-reviewed">
                            <TextField label="Was information reviewed?" select value={edittedFinding?.reviewed || assessmentFinding.reviewed || ''} onChange={(e) => { assessmentFinding.reviewed = e.target.value; props.assessment.assessmentFindings = props.assessmentModel.config.findings; props.onChange(props.assessment);}}>           
                              <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                              <MenuItem value="Not Available">Not Available</MenuItem>
                              <MenuItem value="Reviewed">Reviewed</MenuItem>
                              <MenuItem value="Will be reviewed">Will be reviewed</MenuItem>
                            </TextField>
                          </div>

                          <div className="flex-col">
                            <TextField label="Relevant Findings" type="text" maxLength="300" value={edittedFinding?.comments || assessmentFinding.comments || ''} onChange={(e) => { assessmentFinding.comments = e.target.value; props.assessment.assessmentFindings = props.assessmentModel.config.findings; props.onChange(props.assessment);}}/>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  </Paper>
              </section>
            : null}

            { !!props.assessmentModel && props.assessment.assigned?.length && users.length ?
              <section>
                <Paper className="paper" >
                  <div className="flex-row">
                    <h2 className="flex-col delegateTitle">Assign Interviews to Inquirers</h2>
                    <div className="flex-col">
                        <HelpToolTip
                            title={
                                <div>
                                    Assign interviews to members of the Threat Team.  These members will be able to conduct interviews for this case.<br/>
                                    <br/>
                                    To add members to your Threat Team go to: <br/>
                                    <a className="boldLink" href="https://users.clpsconsultants.com/">User Management</a>
                                </div>
                                }>
                        </HelpToolTip>
                    </div>
                  </div>

                  { delegateMessage ? 
                    <div>{delegateMessage}</div>
                  : null}
                
                  { props.assessment.assigned.map( (inquirer, index) => {
                    let interviewType = props.assessmentModel.interviewTypes.find( it => it.role === inquirer.role ) 
                    return (
                      <div className="section" key={index}>
                        { interviewType ?
                          <div className='flex-row'>
                            <div className='flex-col interview-assign-dropdown'>
                              <TextField label={props.assessmentModel.interviewTypes.find( it => { return it.role === inquirer.role } ).config.interviewText} select value={inquirer.userId || ''} required onChange={ (e) => handleChangeInquirer(e, inquirer.role, index)}>          
                                { users.map( (user, index) => {
                                    return(
                                      <MenuItem key={index} value={user.userId}>{user.nameText}</MenuItem>
                                    )
                                  }) 
                                }
                              </TextField>
                            </div>
                            <div className='flex-col'>
                              <HelpToolTip title={interviewType.config.helpText}></HelpToolTip>
                            </div>
                          </div>
                        : null }

                        { index+1 === props.assessment.assigned.length || inquirer.role !== props.assessment.assigned[index+1].role ?
                          <div className="add-another">
                              <span className='addAnotherOther' onClick={() => handleAddAnother(inquirer.role, index)}><i className="fa fa-plus"> Add Another</i></span>
                              { index === props.assessment.assigned.length || inquirer.role === props.assessment.assigned[index-1]?.role ?
                              <span className='addAnotherOther' onClick={() => handleRemoveAnother(inquirer.role, index)}><i className="fa fa-minus"> Remove</i></span>
                              : null }
                          </div>
                        : null }
                      </div>
                    )
                  })}

                  <div>
                    <span className='addAnotherOther' onClick={() => { if (props.assessment.additionalParticipants.length) props.assessment.additionalParticipants = []; else props.assessment.additionalParticipants.push({}); props.onChange(props.assessment) }}><i className={ `fa ${ !props.assessment.additionalParticipants.length ? 'fa-plus' : 'fa-minus'}`}> Additional Participants</i></span>
                    { props.assessment.additionalParticipants.length ?
                    <div className="flex-row">
                      { props.assessment.additionalParticipants.map( (additionalParticipant, index) => {
                      return (
                        <div className="flex-row section" key={index}>
                          <div className='flex-row'>
                            <div className="flex-col">
                              <TextField type="text" maxLength="100" label="Name" required value={additionalParticipant.name || ''} onChange={ (e) => { props.assessment.additionalParticipants[index].name = e.target.value; props.onChange(props.assessment) }} />           
                            </div>
                            <div className="flex-col">
                              <TextField type="text" maxLength="50" label="Role" required value={additionalParticipant.role || ''} onChange={ (e) => { props.assessment.additionalParticipants[index].role = e.target.value; props.onChange(props.assessment) }} />           
                            </div>
                          </div>
                          { index+1 === props.assessment.additionalParticipants.length ?
                            <div className="add-another">
                                <span className='addAnotherOther' onClick={() => { props.assessment.additionalParticipants.push({}); props.onChange(props.assessment)}}><i className="fa fa-plus"> Add Another</i></span>
                            </div>
                          : null }
                        </div>
                      )
                    })}
                    </div>
                    : null }
                  </div>
                </Paper>
              </section>
            : null }
    </div>
  )
}
