import React, { useState, useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { getCookie } from 'react-use-cookie';

import { LicenseInfo } from '@mui/x-license';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE_KEY);

import userService from '../services/user';

import Tabs from './ThreatAssessment/Tabs';
import ThreatAssessmentList from './ThreatAssessment/List/List'
import ThreatAssessmentArchive from './ThreatAssessment/List/Archive'
import ThreatAssessmentReview from './ThreatAssessment/List/Review'
import ThreatAssessmentCreate from './ThreatAssessment/Create/Create'
import ThreatAssessmentDetail from './ThreatAssessment/Detail'
import ThreatAssessmentInterview from './ThreatAssessment/Interview/Interview'
import ThreatAssessmentReport from './ThreatAssessment/Report'
import ThreatAssessmentResolve from './ThreatAssessment/Resolve'
import ThreatAssessmentNotes from './ThreatAssessment/Notes'
import ThreatAssessmentAnalyze from './ThreatAssessment/Analyze'
import ThreatAssessmentFile from './ThreatAssessment/File'
import AssessmentReview from './ThreatAssessment/Review/Review'
import AssetAcknowledge from './token/Notification/Acknowledge'

import Form from './ThreatAssessment/Forms/Form'

import AppError from '../components/AppError'
import Sidenav from '../components/Sidenav'
import Message from '../components/Message'
import Header from '../components/Header'
import Tutorial from '../components/Tutorial'

import ga from 'react-ga';
import AppContext from '../context/app'

import LocalStorageService from '../services/localstorage'
let LocalStorage = new LocalStorageService()


import '../css/app.css'


export default function App(props) {
  const context = useContext(AppContext)
  const location = useLocation()

  const [loading, setLoading] = useState(true)
  const [showHeader, setShowHeader] = useState(true)
  const [showSideNav, setShowSideNav] = useState(true)
  const [showTutorial, setShowTutorial] = useState(false)
  
  let accessTokenPage = () => {
    return location.pathname.includes('token/notification/acknowledge')
  }

  useEffect(() => {
    //act like you changed pages by going to top
    window.scroll(0, 0)

    //Log in Analytics
    ga.set({ page: location.pathname });
    ga.pageview(location.pathname);

    // //security
    if (!getCookie('clps') && !accessTokenPage())
      window.location = 'https://login.clpsconsultants.com/'
    
  }, [location]);

  useEffect( async () => {
    if (accessTokenPage()){
      setShowHeader(false)
      setShowSideNav(false)
      setLoading(false)
      return
    }
    //Default page
    if (!getCookie('clps'))
      window.location = 'https://login.clpsconsultants.com/'
    else {
      try {
        //load up data and goto default page
        let user = await new userService().get()
        if (!user) {
          window.location = 'https://login.clpsconsultants.com/logout'
          return
        }

        console.log('logged in as: ', user.userId)
        ga.set({ user: user.userId });

        context.updateUser(user)
        setLoading(false)  

        if (!LocalStorage.get('shownTutorial')) {
          let systems = await new userService().getSystems()
          if (systems.find( s => s.system === 'assessmentSBTMT')) {
            setShowTutorial(true)
            LocalStorage.set('shownTutorial', true)
          }
        }
      }
      catch (err) {
        console.log(err);
        if (!process.env.REACT_APP_NO_RESET_ON_ERROR)
          window.location = 'https://login.clpsconsultants.com/'
      }

    }
  },[])

  let closeTutorial = () =>{
    setShowTutorial(false)
  }


  return (
    <div>
      {!loading && context.user ?
        <div>
          <AppError>
            { showTutorial ?
              <Tutorial open={showTutorial} onClose={closeTutorial}></Tutorial>
            : null }
            <Message context={context}></Message>
            { showHeader ?
              <Header context={context} />
            : null }
            <section className="flex-row content">
              <div className="flex-col content">
                { showSideNav ? 
                  <section className="flex-col sidebar-container">
                    <Sidenav context={context} />
                  </section>
                : null }
                
                <div className="flex-row">
                  <section className={`flex-col main-container ${!showSideNav ? 'no-sidenav': ''}`}>
                      <Switch>
                        <Route path="/list" component={ThreatAssessmentList} />
                        <Route path="/archive" component={ThreatAssessmentArchive} />
                        <Route path="/review" component={ThreatAssessmentReview} />
                        <Route path="/create" component={ThreatAssessmentCreate} />
                        <Route path="/edit/:threatAssessmentId" component={ThreatAssessmentCreate} />
                        <Route path="/interview/edit/:threatAssessmentInterviewId" component={ThreatAssessmentInterview} />
                        <Route path="/interview/:threatAssessmentId/:role" component={ThreatAssessmentInterview} />
                        <Route path="/analyze" component={ThreatAssessmentAnalyze} />
                        <Route path="/token/notification/acknowledge" component={AssetAcknowledge} />

                        <Route path="/assessment/:threatAssessmentId" exact={true}><Tabs {...props} load={true} /></Route>
                        <Route path="/assessment/*/:threatAssessmentId">
                          <Tabs {...props} disableRouting={true} />
                          <Route path="/assessment/detail/:threatAssessmentId" component={ThreatAssessmentDetail}/>
                          <Route path="/assessment/report/:threatAssessmentId" component={ThreatAssessmentReport} />
                          <Route path="/assessment/resolve/:threatAssessmentId" component={ThreatAssessmentResolve} />
                          <Route path="/assessment/notes/:threatAssessmentId" component={ThreatAssessmentNotes} />
                          <Route path="/assessment/file/:threatAssessmentId" component={ThreatAssessmentFile} />
                          <Route path="/assessment/review/review/:threatAssessmentId" component={AssessmentReview} />
                          <Route path="/assessment/interview/edit/:threatAssessmentInterviewId/:threatAssessmentId" component={ThreatAssessmentInterview} />
                          <Route path="/assessment/interview/:role/:threatAssessmentId" component={ThreatAssessmentInterview} exact={true}/>                          
                          <Route path="/assessment/forms/edit/:formAnswerId/:threatAssessmentId" component={Form} />
                          <Route path="/assessment/forms/:path/:threatAssessmentId" component={Form} />
                        </Route>

                        <Redirect path="/" to={context.user.reviewThreats ? "/review" : "/list"} />
                      </Switch>
                  </section>
                </div>
              </div>
            </section>
          </AppError>
        </div>
        : null}
    </div>
  )
}