import React, { useEffect, useReducer, useContext } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, TextField, FormControl, RadioGroup, Radio, FormControlLabel, Checkbox } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import threatAssessmentService from '../../../services/threatAssessment';
import AppContext from '../../../context/app'
import formService from '../../../services/assessment/form/form';

const formId = 3

const classificationMonitoringEndDateMap = {
    "Medium Level of Concern": 90,
    "High Level of Concern": 180
}

export default function Form(props) {
    const context = useContext(AppContext)
    const [form, setForm] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { threatAssessmentId: props.threatAssessmentId, formId, data: { meetingDate: moment() } }
    );
    
    useEffect( async () => {
        if (props.editForm)
            setForm(props.editForm)
        else
        {
            let loadedAssessment = await new threatAssessmentService().get(props.threatAssessmentId)
            if (classificationMonitoringEndDateMap[loadedAssessment.resolutionClassification]){
                form.data.monitoringEndDate = moment().add(classificationMonitoringEndDateMap[loadedAssessment.resolutionClassification],'days')             
                setForm({...form})
            }
            
        }
    },[])

    const handleSave = async (event) => {
        event.preventDefault();
        try {
            form.sendForReviewType = 'followUp'
            if (props.editForm)
                await new formService().update(form)
            else
                await new formService().save(form)
            props.history.push({pathname: `/assessment/${form.threatAssessmentId}` });
            context.toastSuccess('Form Saved')
        }
        catch(err){
        console.log(err)
        context.toastError('Form could not be saved')
        Sentry.captureException(err);
        }
    }


    return (
    <div id="assessmentForm">
        <form onSubmit={handleSave}>
            <Paper className="paper section">
                {props.editForm?.timeStamp ? 
                <div className="timeStamp">Submitted {moment(props.editForm?.timeStamp).format('MM/DD/YYYY')}</div>                
                : null}

                { !props.editForm ? <div>When the SBTM places a student on an SSMP, the SBTMT is required to meet monthly and assess the efficacy of the SSMP. If the SSMP is having the desired results, then no modifications to the SSMP are necessary. The SBTMT may in its discretion eliminate or modify SSMP requirements. However, if desired results are not being obtained through the SSMP, then the SBTMT should consider modifying or adding requirements to the SSMP. If at the end of the minimum monitoring period the SBTMT determines that it is appropriate to terminate the SSMP, then case should be closed.</div> : null }
                <div className="section flex-row">
                    <div className="flex-col">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker disableFuture={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="Meeting Date" value={form.data?.meetingDate || null} onChange={(e) => {if (!e) { return }; form.data.meetingDate = e; setForm(form)}}/>
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex-col">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker disablePast={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="Date Mandatory Monitoring Period Ends" value={form.data?.monitoringEndDate || null} onChange={(e) => {if (!e) { return }; form.data.monitoringEndDate = e; setForm(form)}}/>
                        </LocalizationProvider>
                    </div>
                </div>

                <div className="flex-row">
                    <div className="flex-col">
                        <TextField label='State the Components and Requirements in the SSMP' minRows={4}  inputProps={{ maxLength: 2000}} required multiline type="text" value={form.data?.ssmpRequirements || ''} onChange={(e) => { if (!form.data) form.data = {}; form.data.ssmpRequirements = e.target.value; setForm(form) }} />
                    </div>
                </div>


                <div className="flex-row">
                    <div className="flex-col">
                        <TextField label='After review, is the SBTMT modifying the SSMP? (If yes, how and if not, why not?)' minRows={4} required multiline type="text" value={form.data?.modifySSMPComment || ''} onChange={(e) => { form.data.modifySSMPComment = e.target.value; setForm(form)}}/>
                    </div>
                </div>
                 
                <div className="flex-row">
                    <div className="flex-col">
                        <TextField label='Has the District Threat Management Team provided any input to the SBTMT regarding this SSMP? (If yes, what?)'  inputProps={{ maxLength: 200}} minRows={4} multiline type="text" value={form.data?.dtmtHelpModifySSMPComment || ''} onChange={(e) => { form.data.dtmtHelpModifySSMPComment = e.target.value; setForm(form)}}/>
                    </div>
                </div>

                <div>If the mandatory monitoring period is expiring, will the SSPM be:</div>
                <FormControl component="fieldset">
                    <RadioGroup row>
                        <FormControlLabel label="Terminated" control={<Radio required />} checked={ form.data?.monitoringPeriodStatus === 'Terminated' } type="radio" required onChange={() => { form.data.monitoringPeriodStatus = 'Terminated'; setForm(form) } }/>
                        <FormControlLabel label="Extended" control={<Radio required />} checked={  form.data?.monitoringPeriodStatus === 'Extended' } type="radio" required onChange={() => { form.data.monitoringPeriodStatus = 'Extended'; setForm(form) } }/>
                        <FormControlLabel label="Has Not Expired" control={<Radio required />} checked={  !form.data?.monitoringPeriodStatus } type="radio" required onChange={() => { form.data.monitoringPeriodStatus = null; setForm(form) } }/>
                    </RadioGroup>
                </FormControl>

                { form.data?.monitoringPeriodStatus === 'Extended' ?
                    <div>
                    <div className="flex-row">
                        <div className="flex-col">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker disablePast={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="New Expiration Date" value={form.data?.monitoringPeriodExtendedDate || null} onChange={(e) => {if (!e) { return }; form.data.monitoringPeriodExtendedDate = e; setForm(form)}}/>
                            </LocalizationProvider>
                        </div>
                    </div>

                    <div className="flex-row">
                        <div className="flex-col">
                            <TextField label='If the SSMP is being extended, state why' required maxLength="400" type="text" value={form.data?.monitoringPeriodExtendedComment || ''} onChange={(e) => { form.data.monitoringPeriodExtendedComment = e.target.value; setForm(form)}}/>
                        </div>
                    </div>
                </div>
                : null }

                { form.data?.monitoringPeriodStatus === 'Terminated' ?
                    <div className="flex-row">
                        <div className="flex-col">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker disablePast={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="Termination Date" value={form.data?.monitoringPeriodTerminiationDate || null} onChange={(e) => {if (!e) { return }; form.data.monitoringPeriodTerminiationDate = e; setForm(form)}}/>
                            </LocalizationProvider>
                        </div>
                    </div>
                : null }                
            </Paper>
            <div>
                <Button variant="outlined" type="submit">
                    <Icon className="fa fa-save" /> { props.editForm ? 'Edit' : 'Save' }
                </Button>
            </div>
        </form>
    </div>
    )
}